import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import { Image, KPanel } from "../../../components/web-components";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKAvatarMenuProps {
  onMenuClick?: () => void;
  onButtonClick?: () => void;
  imageValue?: string;
  style: any;
  widthType?: "fill" | "content";
  menuOpeningType?: "click" | "hover";
  imageOrIcon?: "image" | "icon";
}

interface IKAvatarMenuState {}

class KAvatarMenu extends PureComponent<IKAvatarMenuProps & CommonProps, IKAvatarMenuState> {
  constructor(props: IKAvatarMenuProps) {
    super(props);
    this.state = {};
  }

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  handleButtonClick = (e: any) => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  handleMenuClick = (e: any) => {
    if (this.props.onMenuClick) {
      this.props.onMenuClick();
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (props.children) {
      delete props.children;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    props.style = style;
    return props;
  };

  getOpenMenuTypeProps = () => {
    const props: any = _.clone(this.props);
    let menuOpeningType: string = "click";
    if (props.menuOpeningType == "click") {
      props.menuOpeningType = menuOpeningType;
      return props.menuOpeningType;
    }
    if (props.menuOpeningType == "hover") {
      menuOpeningType = "hover";
      props.menuOpeningType = menuOpeningType;
      return props.menuOpeningType;
    }
  };

  getMenuItems = () => {
    if (Array.isArray(this.props?.children)) {
      const menuItems: any = _.clone(this.props?.children);
      return menuItems;
    }
    return <></>;
  };

  render(): ReactNode {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {React.Children.map(this.getMenuItems(), (child: any, i) => {
          return (
            <Menu.Item {...child?.props} key={i}>
              {child.props.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return (
      <Dropdown overlay={menu} trigger={this.getOpenMenuTypeProps()}>
        <KPanel>
          {this.props.imageOrIcon == "icon" ? (
            <MoreOutlined {...this.getProps()} onClick={this.handleButtonClick} />
          ) : (
            <Image {...this.getProps()} value={this.props.imageValue} onClick={this.handleButtonClick} />
          )}
        </KPanel>
      </Dropdown>
    );
  }
}

const kAvatarMenu = withCommonEvents(KAvatarMenu);
export { kAvatarMenu as KAvatarMenu };
