import { Slider } from "antd";
import { SliderBaseProps } from "antd/lib/slider";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
// do not use this component directly, it should only be called from KSlider

interface KSliderRangeProps extends SliderBaseProps {
  style: any;
  sliderColor: string;
  handleColor: string;
  value: string;
  onChange: any;
  id?: string;
}

interface KSliderState {}

class KSliderRange extends PureComponent<KSliderRangeProps & CommonProps, KSliderState> {
  constructor(props: KSliderRangeProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const node = findDOMNode(this) as Element;
    if (this.props && this.props.sliderColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-track")[0] as HTMLElement;
      sliderTract.style.backgroundColor = this.props.sliderColor;
    }
    if (this.props && this.props.handleColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract.style.borderColor = this.props.handleColor;
    }
  }

  getValue = () => {
    if (this.props && this.props.value) {
      let value = "";
      value = _.clone(this.props.value);
      const tmp = value!.split(",");
      const newValue = [0, 0] as any;
      newValue[0] = parseInt(tmp[0]);
      newValue[1] = parseInt(tmp[1]);
      return newValue;
    }
    return undefined;
  };

  onChange = (value: [number, number]) => {
    const stringValue = `${value[0].toString()},${value[1].toString()}`;
    if (this.props.onChange) {
      this.props.onChange(stringValue);
    }
  };

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.sliderColor) {
      delete props.sliderColor;
    }
    if (props.handleColor) {
      delete props.handleColor;
    }
    if (props.value) {
      delete props.value;
    }
    delete props.range;
    return props;
  };

  render(): ReactNode {
    if (this.props.value) {
      return (
        <>
          <Slider {...this.getProps()} range={true} value={this.getValue()} onChange={this.onChange} />
        </>
      );
    }
    return (
      <>
        <Slider {...this.getProps()} range={true} onChange={this.onChange} />
      </>
    );
  }
}

const kSliderRange = withCommonEvents(KSliderRange);
export { kSliderRange as KSliderRange };
