import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ReactSystemFunctions } from "../../../shared/utilty/react-system-functions";

const { Option } = Select;

interface IKLanguageSelectProps {
  value: string;
  languages: ILang[];
  style: any;
  onChange: (value: string) => void;
}

export interface ILang {
  Id: number;
  Name: string;
  ShortName: string;
  IsDefault: boolean;
}

interface IKLanguageSelectState {}

class KLanguageSelect extends PureComponent<IKLanguageSelectProps & CommonProps, IKLanguageSelectState> {
  constructor(props: IKLanguageSelectProps) {
    super(props);
    this.state = {};
  }

  getStyle = (): any => {
    if (this.props.style) {
      return this.props.style;
    }
    return {};
  };

  handleChange = (value: any, option: DefaultOptionType) => {
    if (this.props.onChange) {
      this.props.onChange(value);
      ReactSystemFunctions.setLanguage(this, value);
    }
  };

  render(): ReactNode {
    return (
      <>
        <Select
          style={this.getStyle()}
          className={this.props.className}
          value={this.props.value}
          onChange={this.handleChange}
        >
          {this.props.languages &&
            ReactSystemFunctions.getAvailableLanguages(this.props.languages)
              ?.sort((a, b) => a?.name?.localeCompare(b.name))
              ?.map((s) => {
                return (
                  <Option key={s?.name} value={s?.name}>
                    {s.shortName}
                  </Option>
                );
              })}
        </Select>
      </>
    );
  }
}

const kLanguageSelect = withCommonEvents(KLanguageSelect);
export { kLanguageSelect as KLanguageSelect };
