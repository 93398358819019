import { Menu } from "antd";
import { Guid } from "guid-typescript";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KIcon } from "../icon/k-icon";

interface IKAvatarItemProps {
  iconName?: string;
  onClick?: () => void;
  label: string;
}

interface IKAvatarItemState {}

class KAvatarMenuItem extends PureComponent<IKAvatarItemProps & CommonProps, IKAvatarItemState> {
  key = Guid.create().toString();

  constructor(props: IKAvatarItemProps) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render(): ReactNode {
    let iconProp = {};
    if (this.props.iconName) {
      iconProp = { icon: <KIcon iconName={this.props.iconName} /> };
    }

    return (
      <Menu.Item {...this.props} onClick={this.handleClick} key={this.key} {...iconProp}>
        {this.props.label}
      </Menu.Item>
    );
  }
}

const kAvatarMenuItem = withCommonEvents(KAvatarMenuItem);
export { kAvatarMenuItem as KAvatarMenuItem };
