import { Slider } from "antd";
import { SliderBaseProps, SliderSingleProps } from "antd/lib/slider";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KSliderRange } from "./k-slider-range";

interface KSliderProps extends SliderBaseProps, SliderSingleProps {
  style: any;
  sliderColor: string;
  handleColor: string;
  onChange: any;
  id?: string;
}

interface KSliderState {}

class KSlider extends PureComponent<KSliderProps & CommonProps, KSliderState> {
  constructor(props: KSliderProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const node = findDOMNode(this) as Element;
    if (this.props && this.props.sliderColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-track")[0] as HTMLElement;
      sliderTract.style.backgroundColor = this.props.sliderColor;
    }
    if (this.props && this.props.handleColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract.style.borderColor = this.props.handleColor;
      if (node.getElementsByClassName("ant-slider-handle").length > 1) {
        const sliderTract2: HTMLElement = node.getElementsByClassName("ant-slider-handle")[1] as HTMLElement;
        sliderTract2.style.borderColor = this.props.handleColor;
      }
    }
  }

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.sliderColor) {
      delete props.sliderColor;
    }
    if (props.handleColor) {
      delete props.handleColor;
    }
    return props;
  };

  render(): ReactNode {
    if (this.props.range) {
      return <KSliderRange {...this.props} />;
    }
    return (
      <>
        <Slider {...this.getProps()} range={false} />
      </>
    );
  }
}

const kSlider = withCommonEvents(KSlider);
export { kSlider as KSlider };
