import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadProps } from "antd/lib/upload/interface";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKFilePickerProps extends Omit<UploadProps, "onChange"> {
  label: string;
  widthType?: "fill" | "content";
  onChange: (base64: string) => void;
  maxSize: number;
  value: string;
}

interface IKFilePickerState {}

class KFilePicker extends PureComponent<IKFilePickerProps & CommonProps, IKFilePickerState> {
  constructor(props: IKFilePickerProps) {
    super(props);
    this.state = {};
  }

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  getUploadProps = () => {
    const allProps: any = _.clone(this.props);
    const uploadProps = {} as any;

    uploadProps.accept = allProps.accept;
    return uploadProps;
  };

  getButtonProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);

    if (props.label) {
      delete props.label;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    props.style = style;
    delete props.widthType;
    delete props.onChange;
    delete props.accept;
    delete props.maxSize;
    return props;
  };

  handleChange = (info) => {
    if (info.file) {
      let { maxSize } = this.props;
      if (!maxSize) maxSize = 1024;
      if (info.file.size / 1024 > 1024) {
        alert(`Max size reached. Max size: ${maxSize}kb. - Uploaded file: ${Math.round(info.file.size / 1024)}kb.`);
        return;
      }
      this.getBase64(info.file, (base64) => {
        this.props.onChange(base64);
      });
    }
  };

  getBase64(file, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  render(): ReactNode {
    return (
      <Upload
        {...this.getUploadProps()}
        beforeUpload={() => {
          return false;
        }}
        onChange={this.handleChange}
        fileList={[]}
      >
        <Button {...this.getButtonProps()} icon={<UploadOutlined />}>
          {this.props.label}
        </Button>
      </Upload>
    );
  }
}

const kFilePicker = withCommonEvents(KFilePicker);
export { kFilePicker as KFilePicker };
