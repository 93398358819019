import { Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

type IKToggleProps = SwitchProps;

interface IKToggleState {}

class KToggle extends PureComponent<IKToggleProps & CommonProps, IKToggleState> {
  constructor(props: IKToggleProps & CommonProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <Switch {...this.props} />
      </>
    );
  }
}

const kToggle = withCommonEvents(KToggle);
export { kToggle as KToggle };
