import { Steps } from "antd";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKStepProps {}

interface IKStepState {}

class KSteps extends PureComponent<IKStepProps & CommonProps, IKStepState> {
  constructor(props: IKStepProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <Steps {...this.props}></Steps>;
  }
}

const kSteps = withCommonEvents(KSteps);
export { kSteps as KSteps };
