import { Rate } from "antd";
import { RateProps } from "antd/lib/rate";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

type IKRatingProps = RateProps;

interface IKRatingState {}

class KRating extends PureComponent<IKRatingProps & CommonProps, IKRatingState> {
  constructor(props: IKRatingProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <Rate {...this.props} />
      </>
    );
  }
}

const kRating = withCommonEvents(KRating);
export { kRating as KRating };
