import { Input } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

const { Password } = Input;
interface IKPasswordProps {
  value?: string;
  placeholder: string;
  style: any;
}

interface IKPasswordState {
  uniqueKey: Guid;
}

class KPassword extends PureComponent<IKPasswordProps & CommonProps, IKPasswordState> {
  constructor(props: IKPasswordProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    if (this.props.style?.backgroundColor || this.props.style?.color) {
      const dynamic_style = document.getElementById("dynamic_style");
      if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
        dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${this.getDynamicCss()}`;
      }
    }
  };

  getBgColor = (): string | undefined => {
    if (this.props.style?.backgroundColor) {
      return `background-color: ${this.props.style?.backgroundColor} !important;`;
    }
  };

  getColor = (): string | undefined => {
    if (this.props.style?.color) {
      return `color: ${this.props.style?.color} !important;`;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    const bgColor = this.getBgColor();
    const color = this.getColor();
    let result = "";
    if (bgColor || color) {
      result += `.${className.trim()} > .ant-input {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;

      result += `
      .${className.trim()} > span > span {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kpassword_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Password className={this.getClassName()} {...this.getProps()} />
      </>
    );
  }
}

const kPassword = withCommonEvents(KPassword);
export { kPassword as KPassword };
