import { Input } from "antd";
import { PureComponent, ReactNode, TextareaHTMLAttributes } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

const { TextArea } = Input;

interface IKTextareaProps extends TextareaHTMLAttributes<any> {
  value?: string;
  allowClear?: boolean;
  autoSize?: boolean;
  bordered?: boolean;
  showCount?: boolean;
}

interface IKTextareaState {}

class KTextarea extends PureComponent<IKTextareaProps & CommonProps, IKTextareaState> {
  constructor(props: IKTextareaProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <TextArea {...this.props} />
      </>
    );
  }
}

const kTextarea = withCommonEvents(KTextarea);
export { kTextarea as KTextarea };
