import { Input } from "antd";
import MaskedInput from "antd-5-mask-input";
import { InputProps } from "antd/lib/input";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { KIcon } from "../..";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKTextboxProps extends InputProps {
  prefix?: string;
  suffix?: string;
  iconColor?: string;
  mask?: string;
}

interface IKTextboxState {}

class KTextbox extends PureComponent<IKTextboxProps & CommonProps, IKTextboxState> {
  constructor(props: IKTextboxProps) {
    super(props);
    this.state = {};
  }

  handleAffixStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const input = node.getElementsByTagName("input")[0];
    if (!input) {
      return;
    }
    if (this.props.style?.backgroundColor) {
      input.style.backgroundColor = this.props.style?.backgroundColor as any;
    }
    if (this.props.style?.color) {
      input.style.color = this.props.style?.color as any;
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.prefix && props.prefix.length > 0) {
      props.prefix = <KIcon style={{ color: props.iconColor }} iconName={this.props.prefix as string}></KIcon>;
    } else if (props.prefix) {
      delete props.prefix;
    }

    if (props.suffix && props.suffix.length > 0) {
      props.suffix = <KIcon style={{ color: props.iconColor }} iconName={this.props.suffix as string}></KIcon>;
    } else if (props.suffix) {
      delete props.suffix;
    }
    if (props.iconColor) {
      delete props.iconColor;
    }
    return props;
  };

  componentDidMount() {
    if (
      this.props.style &&
      (this.props.style?.backgroundColor || this.props.style?.color) &&
      (this.props.prefix || this.props.suffix)
    ) {
      this.handleAffixStyling();
    }
  }

  render(): ReactNode {
    if (this.props.mask) {
      return <MaskedInput {...this.getProps()} />;
    }
    return (
      <>
        <Input {...this.getProps()}></Input>
      </>
    );
  }
}

const kTextbox = withCommonEvents(KTextbox);
export { kTextbox as KTextbox };
